import * as desktopController from "./desktopController";
import * as mobileController from "./mobileController";
const remoteUtils = await import( 'AAPE_Sync/utilities' );

let controller;
let startTime = Date.now();

if ( BUILD_TARGET === "b2consumer-desktop" || BUILD_TARGET === "b2business-desktop" ) {
	controller = desktopController;
}

if ( BUILD_TARGET === "b2consumer-mobile" || BUILD_TARGET === "b2business-mobile" ) {
	controller = mobileController;
}

let runSetup = function () {

	window.digitalData = ( window.digitalData ? window.digitalData : {} );
	window._T = ( window._T ? window._T : {} );
	window._aape.deployments = ( _aape.deployments ? _aape.deployments : {} );
	window.s = ( window.s ? window.s : {} );

	window._aape.deployments.thirdparties = {
		build: BUILD_TARGET,
		version: CODE_VERSION,
		ts: '2025-02-26T20:10:22.910Z',
		host: remoteUtils.getAnalyticsHost()
	};

};

let proceedWithPubSub = function () {

	if ( _aape.PUB_SUB.broadcastArchive.length ) {

		let broadcastArchive =_aape.PUB_SUB.broadcastArchive;

		broadcastArchive.forEach( function ( broadcast ) {
		
			if ( broadcast.name === "thdcoreanalytics|beacon" && broadcast.data && broadcast.data.ddo && broadcast.data.trigger && broadcast.data.beaconType ) {

				controller.engage( broadcast.data.ddo, broadcast.data.trigger, broadcast.data.beaconType );

			} else if ( broadcast.name === "thdcoreanalytics|piq_impression" && broadcast.data && broadcast.data.eventData && broadcast.data.trigger && broadcast.data.pageInfo ) {

				controller.runMRPassthrough( broadcast.data.eventData, broadcast.data.pageInfo, broadcast.data.trigger );

			}

		} );

	}

	// Listen for the event.
	_aape.PUB_SUB.subscribe( 'thdcoreanalytics|beacon', function ( e ) {

		if ( e && e.ddo && e.trigger && e.beaconType ) {
			controller.engage( e.ddo, e.trigger, e.beaconType );
		}

	} );

	_aape.PUB_SUB.subscribe( 'thdcoreanalytics|piq_impression', function ( e ) {

		if ( e && e.eventData && e.trigger && e.pageInfo ) {
			controller.runMRPassthrough( e.eventData, e.pageInfo, e.trigger );
		}

	} );


};

let initializeLaunchSequence = function () {

	let checkPubSubInterval;

	runSetup();

	// iframed Overlay tracking is currently handled through the parent page.  In other words, the analytics events are passed from the Iframe to
	// its parent page for execution.  We use the _router method to ensure that the Bootstrap that IS included on the Iframe Overlay does not execute
	// any analtics calls (page or events) but allows for the delivery of certain 3rd party tags (ClickTale and TnT primarily).

	if ( !remoteUtils.isOverlay() ) {

		controller.runIndependentTags();

		if (_aape.PUB_SUB ) {

			console.log( LOG_PREFIX + " -- proceedWithPubSub" );
			proceedWithPubSub();

		}
		else {

			checkPubSubInterval = setInterval( function () {

				let timeSinceStart = Date.now() - startTime;

				console.log( "timeSinceStart: ", timeSinceStart );

				if (_aape.PUB_SUB ) {

					clearInterval( checkPubSubInterval );
					proceedWithPubSub();

				}
				else if ( timeSinceStart > 3000 ) {

					clearInterval( checkPubSubInterval );

				}

			}, 100 );

		}

	}
	else {

		controller.runOverlayTags();

	}

};

( function () {

	// An indicator for build_local mappings
	console.log( '%c' + LOG_PREFIX + ' -- Local Build Mapping: ' + CODE_VERSION + ' | ' + BUILD_TARGET + ' | 2025-02-26T20:10:22.910Z', 'background: orange; color: white; display: block; line-height:20px; padding:5px 30px; border: 1px solid red; font-size:1em;' );
	
	if ( document.readyState === "complete" ) {

		// console.log( "readyState complete -- readyState" );
		initializeLaunchSequence();

	}
	else {

		document.addEventListener( 'readystatechange', function () {

			if ( document.readyState === "complete" ) {

				initializeLaunchSequence();

			}

		} );

	}

}() );